import {
  Box,
  Grid,
  SimpleGrid,
  Text,
  Accordion,
  Paper,
  Switch,
  Anchor,
  Badge,
} from '@mantine/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ListingSkeleton from '../../components/Listing/ListingSkeleton';
import FilterElement from '../../components/FilterElement';
import Listing from '../../components/Listing';
import type NFT from '../../models/NFT';
import fetchListings from '../../utils/fetchListings';
import sanitizeListing from '../../utils/sanitizeListing';
import { isValidCollecitonAddressOrEmpty } from '../../utils/address';
import SharedDataContext from '../../components/SharedDataContext';

type ListingCard = {
  apy: number;
  returnRate: number;
  interestAmount: number;
  repayPrice: number;
  repayDays: number;
  price: number;
  id: number;
  nft: NFT;
};

type Filters = {
  verified: boolean;
  collectionAddress: string;
  interestAmount: { min?: number; max?: number };
  returnRate: { min?: number; max?: number };
  price: { min?: number; max?: number };
  days: { min?: number; max?: number };
  apy: { min?: number; max?: number };
};

type Props = {
  walletAddress: string;
};

const Listings = ({ walletAddress }: Props) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<ListingCard[]>([]);
  const [filteredListings, setFilteredListings] = useState<ListingCard[]>([]);
  const [filters, setFilters] = useState<Filters>({
    verified: true,
    collectionAddress: '',
    interestAmount: {},
    returnRate: {},
    price: {},
    days: {},
    apy: {},
  });

  const { wlCollections } = useContext(SharedDataContext);

  const filterListings = useCallback(
    (listings: ListingCard[]) => {
      const { price, days, apy, returnRate, interestAmount } = filters;
      return listings.filter(
        (listing) =>
          (!apy.min || listing.apy >= apy.min) &&
          (!apy.max || listing.apy <= apy.max) &&
          (!price.min || listing.price >= price.min) &&
          (!price.max || listing.price <= price.max) &&
          (!days.min || listing.repayDays >= days.min) &&
          (!days.max || listing.repayDays <= days.max) &&
          (!returnRate.min || listing.returnRate >= returnRate.min) &&
          (!returnRate.max || listing.returnRate <= returnRate.max) &&
          (!interestAmount.min ||
            listing.interestAmount >= interestAmount.min) &&
          (!interestAmount.max ||
            listing.interestAmount <= interestAmount.max) &&
          (filters.verified
            ? wlCollections.includes(listing.nft.address.toLocaleLowerCase())
            : true) &&
          (!filters.collectionAddress ||
            listing.nft.address.toLocaleLowerCase() ===
              filters.collectionAddress.toLocaleLowerCase())
      );
    },
    [filters, wlCollections]
  );

  const fetchMoreListings = useCallback(
    async (lastListingId = 0, append = true) => {
      try {
        let currentListings = await fetchListings(lastListingId, 50);
        currentListings = await Promise.all(
          currentListings
            .filter((listing: any) => +listing.id > 0)
            .map(sanitizeListing)
        );

        if (append) {
          setListings((prevListings) => [...prevListings, ...currentListings]);
        } else {
          setListings(currentListings);
        }

        if (currentListings.length > 0 && currentListings.length < 50) {
          fetchMoreListings(currentListings[currentListings.length - 1].id);
        } else {
          setTimeout(() => setLoading(false), 250);
        }
      } catch (e: any) {
        setTimeout(() => setLoading(false), 250);
        setError(e.message);
      }
    },
    []
  );

  const onNumberFilterChange = useCallback(
    (min: number, max: number, key: string) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [key]: { min, max } };
        if (
          JSON.stringify(newFilters) !== JSON.stringify(prevFilters) &&
          listings[listings.length - 1]
        ) {
          setLoading(true);
          fetchMoreListings(listings[listings.length - 1].id);
        }
        return newFilters;
      });
    },
    [fetchMoreListings, listings]
  );

  const onSwitchFilterChange = useCallback(
    (value: boolean, key: string) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [key]: value };
        if (
          JSON.stringify(newFilters) !== JSON.stringify(prevFilters) &&
          listings[listings.length - 1]
        ) {
          setLoading(true);
          fetchMoreListings(listings[listings.length - 1].id);
        }
        return newFilters;
      });
    },
    [fetchMoreListings, listings]
  );

  const onCollectionFilterChange = useCallback(
    (value: string, key: string) => {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          [key]: isValidCollecitonAddressOrEmpty(value) ? value : '-',
        };
        if (
          JSON.stringify(newFilters) !== JSON.stringify(prevFilters) &&
          listings[listings.length - 1]
        ) {
          setLoading(true);
          fetchMoreListings(listings[listings.length - 1].id);
        }
        return newFilters;
      });
    },
    [fetchMoreListings, listings]
  );

  const onPriceChange = useCallback(
    (v1: any, v2: any) => onNumberFilterChange(v1, v2, 'price'),
    [onNumberFilterChange]
  );

  const onInterestChange = useCallback(
    (v1: any, v2: any) => onNumberFilterChange(v1, v2, 'interestAmount'),
    [onNumberFilterChange]
  );

  const onDurationChange = useCallback(
    (v1: any, v2: any) => onNumberFilterChange(v1, v2, 'days'),
    [onNumberFilterChange]
  );

  const onReturnRateChange = useCallback(
    (v1: any, v2: any) => onNumberFilterChange(v1, v2, 'returnRate'),
    [onNumberFilterChange]
  );

  const onAPYChange = useCallback(
    (v1: any, v2: any) => onNumberFilterChange(v1, v2, 'apy'),
    [onNumberFilterChange]
  );

  const onVerifiedChange = useCallback(
    (value: boolean) => onSwitchFilterChange(value, 'verified'),
    [onSwitchFilterChange]
  );

  const onCollectionAddressChange = useCallback(
    (v: string) => onCollectionFilterChange(v, 'collectionAddress'),
    [onCollectionFilterChange]
  );

  useEffect(() => {
    fetchMoreListings(0, false);
  }, [fetchMoreListings]);

  useEffect(() => {
    setFilteredListings(filterListings(listings));
  }, [listings, filterListings]);

  return (
    <Box sx={{ maxWidth: 1536, margin: '0 auto' }}>
      <Grid px="sm" py="xl" columns={24} mx={0}>
        <Grid.Col span={24} lg={5} pr="md">
          <Paper
            shadow="xs"
            radius="md"
            p="lg"
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
            })}
          >
            <Text size="lg" weight="bold" mb="md">
              Filters
            </Text>
            <Accordion
              multiple
              offsetIcon={false}
              sx={(theme) => ({
                borderRadius: theme.radius.md,
                overflow: 'hidden',
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[7]
                    : theme.colors.gray[2],
                '& button:hover': {
                  backgroundColor:
                    theme.colorScheme === 'dark'
                      ? theme.colors.dark[8]
                      : theme.colors.gray[3],
                },
              })}
            >
              <Accordion.Item label="Collection Address">
                <FilterElement
                  type="text"
                  placeholder1="Paste collection address here..."
                  onChange={onCollectionAddressChange}
                />
              </Accordion.Item>
              <Accordion.Item label="Loan Amount (AVAX)">
                <FilterElement type="number" onChange={onPriceChange} />
              </Accordion.Item>
              <Accordion.Item label="Total Interest (AVAX)">
                <FilterElement type="number" onChange={onInterestChange} />
              </Accordion.Item>
              <Accordion.Item label="Loan Duration (days)">
                <FilterElement type="number" onChange={onDurationChange} />
              </Accordion.Item>
              <Accordion.Item label="Return Rate (%)">
                <FilterElement type="number" onChange={onReturnRateChange} />
              </Accordion.Item>
              <Accordion.Item label="APY (%)">
                <FilterElement type="number" onChange={onAPYChange} />
              </Accordion.Item>
            </Accordion>
            <Box mt="lg" mb="md">
              <Switch
                color="teal"
                checked={filters.verified}
                onChange={(e) => onVerifiedChange(e.currentTarget.checked)}
                aria-label="Only show verified listings"
                label="Only show verified listings"
              />
            </Box>
            <Box>
              <Anchor
                target="_blank"
                rel="noopener noreferrer"
                href="https://forms.gle/PoRFXT2DBc9D1pbR8"
              >
                <Text
                  size="xs"
                  weight="bold"
                  variant="gradient"
                  gradient={{ from: 'teal', to: 'blue', deg: 60 }}
                >
                  Apply now for the{' '}
                  <Badge color="teal" mx={2}>
                    verified
                  </Badge>{' '}
                  badge
                </Text>
              </Anchor>
            </Box>
          </Paper>
        </Grid.Col>
        <Grid.Col span={24} lg={19}>
          {!error && (loading || filteredListings.length > 0) && (
            <SimpleGrid
              cols={5}
              spacing="sm"
              breakpoints={[
                { maxWidth: 1366, cols: 4, spacing: 'sm' },
                { maxWidth: 980, cols: 3, spacing: 'sm' },
                { maxWidth: 755, cols: 2, spacing: 'sm' },
                { maxWidth: 600, cols: 1, spacing: 'sm' },
              ]}
            >
              {!loading
                ? filteredListings.map((listing) => (
                    <Link key={listing.id} to={`/listing/${listing.id}`}>
                      <Listing
                        id={listing.id}
                        name={listing.nft.metadata?.name ?? ''}
                        image={listing.nft.metadata?.image ?? ''}
                        listingInfo={{
                          apy: listing.apy,
                          price: listing.price,
                          duration: listing.repayDays,
                          returnRate: listing.returnRate,
                          interestAmount: listing.interestAmount,
                        }}
                        data={listing.nft}
                        tag={
                          wlCollections.includes(
                            listing.nft.address.toLowerCase()
                          )
                            ? 'Verified'
                            : 'Not Verified'
                        }
                        tagColor={
                          wlCollections.includes(
                            listing.nft.address.toLowerCase()
                          )
                            ? 'teal'
                            : 'pink'
                        }
                      />
                    </Link>
                  ))
                : Array(5)
                    .fill('')
                    .map((el, i) => <ListingSkeleton key={i} />)}
            </SimpleGrid>
          )}
          {!loading && !error && filteredListings.length === 0 && (
            <Box p="md">
              <Text size="lg" weight="bold" mb="md">
                No listings found
              </Text>
              <Text size="md">
                Try adjusting your filters to find a listing that matches your
                needs.
              </Text>
            </Box>
          )}
          {error && (
            <Box p="md">
              <Text size="lg" weight="bold" mb="md">
                Error
              </Text>
              <Text size="md">{error}</Text>
            </Box>
          )}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default Listings;
