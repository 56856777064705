import Web3 from 'web3';

export const MAIN_NETWORK_ID = 43114;
export const TEST_NETWORK_ID = 43113;
export const DEV_NETWORK_ID = 43112;

export const switchNetwork = async (isTestEnv: boolean) => {
  let chainId = MAIN_NETWORK_ID;
  if (isTestEnv) {
    chainId = TEST_NETWORK_ID;
  }

  const web3 = window.web3Client || new Web3(Web3.givenProvider);

  try {
    await web3.currentProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(chainId) }],
    });
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      alert('This network is not in your MetaMask wallet. Please add it.');
    }
  }
};

export const getNetworkId = async (): Promise<number> => {
  if (!window.web3Client) {
    return 0;
  }

  const networkId = await window.web3Client.eth.net.getId();
  return networkId;
};

export const isMainNetConnected = async (
  currentChainId?: string | number
): Promise<boolean> => {
  const networkId = currentChainId || (await getNetworkId());
  return +networkId === MAIN_NETWORK_ID;
};

export const isTestNetConnected = async (
  currentChainId?: string | number
): Promise<boolean> => {
  const networkId = currentChainId || (await getNetworkId());
  return +networkId === TEST_NETWORK_ID;
};

export const isDevNetConnected = async (
  currentChainId?: string | number
): Promise<boolean> => {
  const networkId = currentChainId || (await getNetworkId());
  return +networkId === DEV_NETWORK_ID;
};
