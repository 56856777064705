import {
  showCustomNotification,
  updateCustomNotification,
} from '../components/NotificationComponent';
import fetchLenderContract from './fetchLenderContract';
import sanitizeListing, { SanitizedListing } from './sanitizeListing';

export const getMyListedLoans = async (
  walletAddress: string
): Promise<SanitizedListing[]> => {
  const id = Date.now().toString();
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    showCustomNotification('FAILED', id);
    return [];
  }

  const listedListings = await lenderInstance.methods
    .getListingsByAddress(walletAddress, 0, false, 0, 0)
    .call();

  const sanitized: SanitizedListing[] = await Promise.all(
    listedListings.map(sanitizeListing)
  );

  return sanitized.sort((a, b) => +b.dateListed - +a.dateListed);
};

export const getMyFundedLoans = async (
  walletAddress: string
): Promise<SanitizedListing[]> => {
  const id = Date.now().toString();
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    showCustomNotification('FAILED', id);
    return [];
  }

  const fundedListings = await lenderInstance.methods
    .getListingsByAddress(walletAddress, 2, false, 0, 0)
    .call();
  const sanitized: SanitizedListing[] = await Promise.all(
    fundedListings.map(sanitizeListing)
  );
  return sanitized.sort((a, b) => +b.dateListed - +a.dateListed);
};
