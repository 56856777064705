import { Card, Skeleton } from '@mantine/core';

const ListingSkeleton = () => (
  <Card shadow="sm" p="lg" radius="md">
    <Skeleton height={150} mb="sm" />
    <Skeleton height={8} mt={6} radius="xl" />
    <Skeleton height={8} mt={6} width="70%" radius="xl" />
    <Skeleton height={8} mt={16} width="40%" radius="xl" />
    <Skeleton height={8} mt={6} width="40%" radius="xl" />
    <Skeleton height={8} mt={16} width="70%" radius="xl" />
  </Card>
);

export default ListingSkeleton;
