const wlCollections: string[] = [
  '0x3c965d9fcaf231f434186e9cf0c1e179307cd211', // Ava Sharks
  '0x6d5087B3082f73D42a32D85e38BC95dcceDe39Bb', // Avax Apes
  '0x8927985B358692815E18F2138964679DcA5d3b79', // Chikn
  '0xCf91B99548b1C17dD1095c0680E20de380635e20', // Roostr
  '0x6caD7faa3F17e2E845cbf6dEFeBDaE44484d448e', // ToonLands
  '0x09BE62ccCA8d6815fbAb477A953A0935176262CF', // MetaDerby
  '0xec675B7C5471c67E9B203c6D1C604df28A89FB7f', // Snail Trail
  '0x00f5D01D86008D14d04E29EFe88DffC75a9cAc47', // FarmLand
  '0xa7aBc3736bc5A4d3d4730eaC5A1e487e56c86789', // Firat
  '0x2a84808279ff8316fBd559B781a1397622257376', // BabySkullz
  '0x3025C5c2aA6eb7364555aAC0074292195701bBD6', // MadSkullz
  '0x4245a1bD84eB5f3EBc115c2Edf57E50667F98b0b', // Hoppers
  '0x6a2B2e8123a046E643faF2CB78D86EB4e8496d2f', // AvaxWarriors
  '0x77E0459EB2fEfA3c772a7FABe45C746377b1ed10', // Smol Land
  '0xC70DF87e1d98f6A531c8E324C9BCEC6FC82B5E8d', // Smol Joe
  '0x880Fe52C6bc4FFFfb92D6C03858C97807a900691', // Apa
  '0x96f020B13F1b8b46D70723C3a3C7b4cC6618e99e', // Peng Gen0
  '0xb42d0f524564cafE2a47CA3331221903Eda83b3C', // Monkeez
  '0x250c53D7307CFA8aABbD58d80E9Fe3C8B64748c8', // Boss Bulls
  '0x13d933eb47f41cbc0687376622d03a8da10feab6', // RYU
  '0x3DD5e0f0659cA8b52925E504FE9f0250bFe68301', // Smol apas
  '0x0c2736f9e9ef8b724cff427555b1231c28b6da61', // Smashverse/Wrestling metaverse
  '0x4a8e6a9b93e55af71882f3afaa10141715ce5fd2', // TBC
  '0xf675a87397a6239eaf95ad948670a5b19d076c59', // Particle
  '0x70a8a70433e5c75f47142668772484413077d939', // NFDNA
  '0x2cD4DbCbfC005F8096C22579585fB91097D8D259', // Smol Creeps
  '0x2EE475A5CdC31c040ba1af3B0C4D66CA5C31c49a', // Mambonauts
  '0x8EAf9c460fD45A128759F242306837Ea6feD300E', // BLOCS_
  '0x5378d068001d7e53d6c0a7ef539fbe213b5eb075', // Funnelheads
  '0x767dc13b7c297b214489d7324652830570a5a108', // TapTapKaboom
  '0x17d11b8802bd5ed3fcc838be353d927b4983c2de', // Happy Sun
  '0xb842344669579ecf4cee12f740520376c4cbc6d1', // Conscious Lines by Gabe Weis
  '0x16b48d4577c43949b824c7fb2c01c2ddb2aefd45', // Rillaz
  '0x5808ea7d522e64f3e35e2adec32267f58670f358', // Butterflies
  '0x21b54507b032d4cc17df8d08a87a58059869bd9f', // For Love 
  '0xc8330C7a4Ba518eE4dD64f66337ee51C4991651c', // Teens On Acid
].map((address) => address.toLowerCase());

export default wlCollections;
