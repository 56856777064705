import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Box, Button, Container, Grid, Text } from '@mantine/core';
import { useWeb3React } from '@web3-react/core';
import NewListing from './pages/NewListing';
import Listings from './pages/Listings';
import Profile from './pages/Profile';
import ListingDetails from './pages/ListingDetails';
import { switchNetwork } from './utils/network';
import { isTestEnv, isWalletAndNetworkMatch } from './utils/checkIfTest';
import ConnectWalletModal from './components/ConnectWalletModal';
import Header from './components/Header';

const App = () => {
  const { account, chainId } = useWeb3React();
  const activeAccount = account || '';

  const [isConnecting, setIsConnecting] = useState(false);
  const [isAutoConnecting, setIsAutoConnecting] = useState(true);
  const [showNetworkMismatch, setShowNetworkMismatch] = useState(false);

  useEffect(() => {
    if (isAutoConnecting || !chainId) {
      return;
    }

    if (account || localStorage.getItem('lastConnected')) {
      isWalletAndNetworkMatch(chainId).then((match) => {
        setShowNetworkMismatch(!match);
      });
    }
  }, [account, chainId, isAutoConnecting]);

  return (
    <main>
      {showNetworkMismatch && (
        <Box
          px="lg"
          py="xs"
          sx={(theme) => ({ background: theme.colors.yellow })}
        >
          <Text color="white" weight="bold" align="center">
            You are not connected to the Avalanche{' '}
            {isTestEnv() ? 'Testnet' : 'Mainnet'}!
          </Text>
        </Box>
      )}
      <Header
        walletAddress={activeAccount}
        onConnectWallet={() => setIsConnecting(true)}
      />
      {activeAccount && !showNetworkMismatch && (
        <Routes>
          <Route
            path="/listings"
            element={<Listings walletAddress={activeAccount} />}
          />
          <Route
            path="/new-listing"
            element={<NewListing walletAddress={activeAccount} />}
          />
          <Route
            path="/profile"
            element={<Profile walletAddress={activeAccount} />}
          />
          <Route
            path="/listing/:listingId"
            element={<ListingDetails walletAddress={activeAccount} />}
          />
          <Route path="*" element={<Navigate to="/listings" replace />} />
        </Routes>
      )}
      {(!activeAccount || showNetworkMismatch) && (
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Grid justify="center" align="center" style={{ minHeight: 300 }}>
                {showNetworkMismatch ? (
                  <Button
                    size="xl"
                    variant="outline"
                    onClick={() => switchNetwork(isTestEnv())}
                  >
                    Switch Network
                  </Button>
                ) : (
                  <Button
                    size="xl"
                    variant="outline"
                    loading={isAutoConnecting}
                    onClick={() => setIsConnecting(true)}
                  >
                    {isAutoConnecting ? 'Connecting' : 'Connect Wallet'}
                  </Button>
                )}
              </Grid>
            </Grid.Col>
          </Grid>
        </Container>
      )}
      <ConnectWalletModal
        opened={isConnecting}
        setIsAutoConnecting={setIsAutoConnecting}
        onClose={() => setIsConnecting(false)}
      />
    </main>
  );
};

export default App;
