import fetchLenderContract from './fetchLenderContract';
import {
  updateCustomNotification,
  showCustomNotification,
} from '../components/NotificationComponent';

export const cancelListing = async (
  listingId: string,
  walletAddress: string
) => {
  const id = Date.now().toString();
  showCustomNotification('LOADING', id);
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Please make sure you are on the correct network before proceeding!',
      id
    );
    return false;
  }

  try {
    await lenderInstance.methods
      .cancelListing(listingId)
      .send({ from: walletAddress });

    updateCustomNotification(
      'SUCCESS',
      'Listing canceled successfully, the NFT is back in your wallet.',
      id
    );
    return true;
  } catch (error) {
    updateCustomNotification(
      'FAILED',
      'Failed to cancel listing, try again and make sure to confirm the transaction.',
      id
    );
    return false;
  }
};

export const fundListing = async (
  listingId: string,
  walletAddress: string,
  priceInWei: number,
  repaymentInWei: number
) => {
  const id = Date.now().toString();
  showCustomNotification('LOADING', id);
  const fee = (repaymentInWei - priceInWei) * 0.1;
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Please make sure you are on the correct network before proceeding!',
      id
    );
    return false;
  }

  try {
    await lenderInstance.methods
      .fundListing(listingId)
      .send({ from: walletAddress, value: priceInWei + fee });

    updateCustomNotification(
      'SUCCESS',
      'Listing funded successfully, you can now see the listing in your profile under the funded listings section.',
      id
    );
    return true;
  } catch (error) {
    updateCustomNotification(
      'FAILED',
      'Failed to cancel listing, try again and make sure to confirm the transaction.',
      id
    );
    return false;
  }
};

export const repayForListing = async (
  listingId: string,
  walletAddress: string,
  value: number
) => {
  const id = Date.now().toString();
  showCustomNotification('LOADING', id);
  const etherValue = window.web3Client.utils.toWei(value.toString(), 'ether');
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Please make sure you are on the correct network before proceeding!',
      id
    );
    return false;
  }

  try {
    await lenderInstance.methods
      .repayForListing(listingId)
      .send({ from: walletAddress, value: etherValue });

    updateCustomNotification(
      'SUCCESS',
      'Loan successfully repayed, the NFT is back in your wallet.',
      id
    );
    return true;
  } catch (error) {
    updateCustomNotification(
      'FAILED',
      'Failed to cancel listing, try again and make sure to confirm the transaction.',
      id
    );
    return false;
  }
};

export const claimCollateral = async (
  listingId: string,
  walletAddress: string
) => {
  const id = Date.now().toString();
  showCustomNotification('LOADING', id);
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Please make sure you are on the correct network before proceeding!',
      id
    );
    return false;
  }

  try {
    await lenderInstance.methods
      .claimCollateralAsFunder(listingId)
      .send({ from: walletAddress });

    updateCustomNotification(
      'SUCCESS',
      'Successfully claimed collateral, you can now find the NFT in your wallet.',
      id
    );
    return true;
  } catch (error) {
    updateCustomNotification(
      'FAILED',
      'Failed to cancel listing, try again and make sure to confirm the transaction.',
      id
    );
    return false;
  }
};
