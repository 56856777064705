import type NFT from '../models/NFT';
import fetchLenderContract from '../utils/fetchLenderContract';
import fetchNftContractInstance from '../utils/fetchNftContractInstance';

export const getIsApprovedForAll = async (
  walletAddress: string,
  nftAddress: string
): Promise<boolean> => {
  const { address: lenderAddress, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    alert('Please make sure you are on the correct network before proceeding!');
    return false;
  }

  const nftInstance = fetchNftContractInstance(nftAddress);
  return nftInstance.methods
    .isApprovedForAll(walletAddress, lenderAddress)
    .call();
};

export const approveContract = async (walletAddress: string, nft: NFT) => {
  const { address: lenderAddress, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    alert('Please make sure you are on the correct network before proceeding!');
    return false;
  }

  const nftInstance = fetchNftContractInstance(nft.address);
  return nftInstance.methods
    .setApprovalForAll(lenderAddress, true)
    .send({ from: walletAddress });
};
