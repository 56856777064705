import { Link } from 'react-router-dom';
import Blockies from 'react-blockies';
import {
  Grid,
  Paper,
  Group,
  Avatar,
  Button,
  Image,
  Chip,
  useMantineTheme,
} from '@mantine/core';
import TraverseLogoBlack from '../../assets/logos/Traverse Logo Black@4x.png';
import TraverseLogoWhite from '../../assets/logos/Traverse Logo White@4x.png';
import styles from './styles.module.css';

type Props = {
  walletAddress: string;
  onConnectWallet: () => void;
};

const Header = ({ walletAddress, onConnectWallet }: Props) => {
  const theme = useMantineTheme();
  const currentWalletPrefix = walletAddress.slice(0, 5);
  const currentWalletSuffix = walletAddress.slice(-4);
  const currentWallet = `${currentWalletPrefix}...${currentWalletSuffix}`;

  return (
    <Paper
      shadow="sm"
      sx={{
        position: 'sticky',
        zIndex: 10,
        top: 0,
      }}
    >
      <Grid align="center" justify="space-between" m={0} px="lg" py="xs">
        <Grid.Col span={3}>
          <Link to="/">
            <Image
              className={styles.logoImg}
              height={50}
              width={250}
              fit="cover"
              src={
                theme.colorScheme === 'light'
                  ? 'https://erenichu.sirv.com/Images/traverse-black.png?w=10%25&h=100%25'
                  : 'https://erenichu.sirv.com/Images/traverse.png?w=10%25&h=100%25'
              }
            />
          </Link>
        </Grid.Col>
        <Grid.Col span={1} />
        <Grid.Col span={8}>
          <Group position="right">
            <Link to="/new-listing">
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              >
                Create a Loan Listing
              </Button>
            </Link>
            {walletAddress && (
              <Link to="/profile">
                <Group spacing={0}>
                  <Avatar radius="xl">
                    <Blockies
                      seed={walletAddress.toLowerCase()}
                      className={styles.walletAvatar}
                    />
                  </Avatar>
                  <Chip
                    size="sm"
                    className={styles.walletChip}
                    sx={(theme) => ({
                      '&::before': {
                        background:
                          theme.colorScheme === 'dark'
                            ? theme.colors.dark[5]
                            : theme.colors.gray[0],
                      },
                    })}
                  >
                    {currentWallet}
                  </Chip>
                </Group>
              </Link>
            )}
            {!walletAddress && (
              <Button variant="outline" onClick={onConnectWallet}>
                Connect Wallet
              </Button>
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default Header;
