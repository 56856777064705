import { useCallback, useEffect, useState } from 'react';
import type NFT from '../models/NFT';
import fetchWalletNfts from '../utils/fetchWalletNfts';
import getNftWithMetadata from '../utils/getNftWithMetadata';

const useWalletNfts = (walletAddress: string, maxNfts = 18) => {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [allNfts, setAllNfts] = useState<NFT[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentWalletAddress, setCurrentWalletAddress] =
    useState(walletAddress);

  const fetchNfts = async (currentWalletAddress: string): Promise<NFT[]> => {
    if (!currentWalletAddress) {
      console.error('Missing currentWalletAddress');
      return [];
    }

    const walletNfts = await fetchWalletNfts(currentWalletAddress);

    const allNfts: NFT[] = walletNfts.map((nftRes) => ({
      address: nftRes.token_address,
      id: nftRes.token_id,
      url: nftRes.token_uri,
      apiMetaData: nftRes.metadata,
    }));

    setCurrentWalletAddress(currentWalletAddress);
    setAllNfts(allNfts);
    return allNfts;
  };

  const populateNfts = async (
    currentNfts: NFT[],
    min = 0,
    max = 0
  ): Promise<NFT[]> =>
    Promise.all(
      currentNfts
        .slice(min, max)
        .map((nft) =>
          getNftWithMetadata(nft.address, nft.id, nft.url, nft.apiMetaData)
        )
    );

  const fetchMoreNfts = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const min = nfts.length;
    const max = nfts.length + maxNfts;
    const populated = await populateNfts(allNfts, min, max);
    setNfts([...nfts, ...populated]);
    setLoading(false);
  }, [allNfts, loading, maxNfts, nfts]);

  const init = useCallback(
    async (currentWalletAddress: string) => {
      const fetchedNfts = await fetchNfts(currentWalletAddress);
      const populated = await populateNfts(fetchedNfts, 0, maxNfts);
      setNfts(populated);
      setLoading(false);
    },
    [maxNfts]
  );

  const refreshNfts = useCallback(
    async (newWalletAddress: string) => {
      setNfts([]);
      setAllNfts([]);
      setLoading(true);
      init(newWalletAddress);
    },
    [init]
  );

  useEffect(() => {
    try {
      init(walletAddress);
    } catch (error) {
      setLoading(false);
      console.error(
        'Something went wrong:',
        process.env.NODE_ENV !== 'production' ? error : {}
      );
    }
  }, [init, walletAddress]);

  return {
    nfts,
    allNfts,
    currentWalletAddress,
    refreshNfts,
    fetchMoreNfts,
    loading,
  };
};

export default useWalletNfts;
