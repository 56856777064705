import NFTContract from '../assets/NFT.json';
import ContractInstance from '../models/ContractInstance';

const fetchNftContractInstance = (contractAddress: string): ContractInstance => {
  const contractABI = JSON.stringify((NFTContract as any).abi);
  const { Contract } = window.web3Client.eth;
  return new Contract(JSON.parse(contractABI), contractAddress);
}

export default fetchNftContractInstance;
