import { useCallback, useContext, useEffect, useState } from 'react';
import { ShieldCheck, ShieldLock, FilePlus } from 'tabler-icons-react';
import {
  Button,
  Group,
  Image,
  Modal,
  NumberInput,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/hooks';
import { cleanNotifications } from '@mantine/notifications';
import {
  approveContract,
  getIsApprovedForAll,
} from '../../api/contractApproval';
import type NFT from '../../models/NFT';
import addListing from '../../api/addListing';
import SharedDataContext from '../SharedDataContext';

type Props = {
  walletAddress: string;
  selectedNft: NFT | null;
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
};

const ListingModal = ({
  walletAddress,
  selectedNft,
  onClose,
  onDone,
  isOpen,
}: Props) => {
  const theme = useMantineTheme();
  const { blockedCollections } = useContext(SharedDataContext);
  const [isContractApproved, setIsContractApproved] = useState(false);
  const [isContractApproving, setIsContractApproving] = useState(false);
  const [isCreatingListing, setIsCreatingListing] = useState(false);

  const allowedForListing = !blockedCollections.includes(
    selectedNft?.address.toLowerCase() ?? ''
  );

  const listingForm = useForm({
    initialValues: {
      price: undefined,
      repayPrice: undefined,
      repayDays: undefined,
    },

    validationRules: {
      price: (value) => !!(value && value >= 0.1),
      repayPrice: (value, values) => !!(value && value > (values?.price ?? 0)),
      repayDays: (value) => !!(value && value >= 1 && value <= 365),
    },
  });

  const onSubmitListing = useCallback(
    async (listingValues: any) => {
      if (!selectedNft) {
        return;
      }

      setIsCreatingListing(true);

      const listingAdded = await addListing(
        walletAddress,
        listingValues,
        selectedNft
      );

      setIsCreatingListing(false);

      if (listingAdded) {
        onDone();
      }
    },
    [walletAddress, selectedNft, onDone]
  );

  const onCloseModal = useCallback(() => {
    onClose();
    setTimeout(() => {
      listingForm.reset();
      setIsContractApproved(false);
      setIsContractApproving(false);
      cleanNotifications();
    }, 500);
  }, [onClose, listingForm]);

  useEffect(() => {
    if (selectedNft) {
      getIsApprovedForAll(walletAddress, selectedNft.address).then(
        (isApproved) => {
          setIsContractApproved(isApproved);
          setIsContractApproving(false);
        }
      );
    }
  }, [selectedNft, walletAddress]);

  return (
    <Modal
      opened={isOpen}
      onClose={onCloseModal}
      closeOnClickOutside={false}
      title={`Create Listing for ${selectedNft?.metadata?.name}`}
    >
      {selectedNft && (
        <Group position="center" mb="sm">
          <Image
            src={selectedNft.metadata?.image}
            alt={selectedNft.metadata?.name}
            imageProps={{
              style: {
                width: 175,
                height: 175,
                aspectRatio: '1 / 1',
                objectFit: 'contain',
                background:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[4]
                    : theme.colors.gray[2],
                borderRadius: 8,
              },
            }}
          />
        </Group>
      )}
      {allowedForListing && (
        <form onSubmit={listingForm.onSubmit(onSubmitListing)}>
          <NumberInput
            required
            min={0}
            precision={2}
            placeholder="10"
            label="Loan Amount (AVAX)"
            {...listingForm.getInputProps('price')}
            disabled={isCreatingListing}
            mb="sm"
          />
          <NumberInput
            required
            min={0}
            precision={2}
            placeholder="10.5"
            label="Repayment Price (AVAX)"
            {...listingForm.getInputProps('repayPrice')}
            disabled={isCreatingListing}
            mb="sm"
          />
          <NumberInput
            required
            min={0}
            placeholder="30"
            label="Loan Duration (days)"
            {...listingForm.getInputProps('repayDays')}
            disabled={isCreatingListing}
            mb="sm"
          />
          <Group direction="column" align="center" mt="lg">
            <Button
              fullWidth
              loading={isContractApproving}
              disabled={!selectedNft || isContractApproved}
              gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              variant={isContractApproved ? 'outline' : 'gradient'}
              leftIcon={
                isContractApproved ? (
                  <ShieldCheck size={14} />
                ) : (
                  <ShieldLock size={14} />
                )
              }
              onClick={async () => {
                if (!selectedNft || isContractApproving || isContractApproved) {
                  return;
                }
                setIsContractApproving(true);
                await approveContract(walletAddress, selectedNft);
                setIsContractApproving(false);
                setIsContractApproved(true);
              }}
            >
              {isContractApproved
                ? 'Lender Contract Approved'
                : 'Approve Lender Contract'}
            </Button>
            <Button
              fullWidth
              variant={
                !selectedNft || isContractApproving || !isContractApproved
                  ? 'outline'
                  : 'gradient'
              }
              loading={isCreatingListing}
              disabled={
                !selectedNft || isContractApproving || !isContractApproved
              }
              gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              leftIcon={<FilePlus size={14} />}
              type="submit"
            >
              {isCreatingListing
                ? 'Pending Confirmation in your Wallet'
                : 'Create Listing'}
            </Button>
          </Group>
        </form>
      )}
      {!allowedForListing && (
        <Group direction="column" align="center" mt="lg">
          <Text>
            This NFT cannot be listed on the platform. <br />
            Please contact the owner of the collection to request verification
            in our Discord channel - AvaSharks.
          </Text>
          <Button fullWidth variant="outline" onClick={onCloseModal}>
            Close
          </Button>
        </Group>
      )}
    </Modal>
  );
};

export default ListingModal;
