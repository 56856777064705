import type { MetaData } from '../models/NFT';
import { isTestEnv } from '../utils/checkIfTest';
import { MAIN_NETWORK_ID, TEST_NETWORK_ID } from '../utils/network';
import fetchNftContractInstance from '../utils/fetchNftContractInstance';
import fetchWithTimeout from '../utils/fetchWithTimeout';

const generateUrl = (nftAddress: string, nftId: string) =>
  `https://api.covalenthq.com/v1/${
    isTestEnv() ? TEST_NETWORK_ID : MAIN_NETWORK_ID
  }/tokens/${nftAddress}/nft_metadata/${nftId}/?quote-currency=USD&format=JSON&key=${
    process.env.REACT_APP_COVALENT_API_KEY
  }`;

const fetchNftMetadata = async (
  nftAddress: string,
  nftId: string,
  ipfsGateway: string
): Promise<MetaData> => {
  const fetchFromApi = async () => {
    let metadata: MetaData = {
      name: '',
      description: '',
      id: nftId,
      image: '',
      imageS: '',
      attributes: [],
    };

    const tokenUri = generateUrl(nftAddress, nftId);
    const apiData = await fetchWithTimeout(tokenUri).then((res) => res.json());

    if (apiData.error) {
      throw new Error(apiData.error_message);
    }

    const nftData = apiData.data?.items?.[0]?.nft_data?.[0];

    if (nftData) {
      metadata.image = nftData.external_data?.image;
      metadata.imageS = nftData.external_data?.image_256;
      metadata.name = nftData.external_data?.name;
      metadata.description = nftData.external_data?.description;
      metadata.attributes = nftData.external_data?.attributes;
    }

    return metadata;
  };

  const fetchFromContract = async () => {
    const contractInstance = fetchNftContractInstance(nftAddress);
    let tokenUri = await contractInstance.methods.tokenURI(nftId).call();
    if (tokenUri) {
      if (tokenUri.startsWith('ipfs://')) {
        tokenUri = tokenUri.replace('ipfs://', ipfsGateway);
      } else if (tokenUri.includes('/ipfs/')) {
        tokenUri = `${ipfsGateway}${tokenUri.split('/ipfs/')[1]}`;
      }

      try {
        return fetch(tokenUri).then((res) => res.json());
      } catch (error) {}
    }
  };

  let metadata: MetaData = {
    name: '',
    description: '',
    id: nftId,
    image: '',
    imageS: '',
    attributes: [],
  };

  try {
    metadata = await fetchFromApi();
  } catch (error) {
    metadata = await fetchFromContract();
  }

  switch (nftAddress) {
    case '0xB842344669579ECf4Cee12f740520376c4CBC6d1':
      metadata.name = `Conscious Lines #${nftId}`;
      break;

    default:
      break;
  }

  return metadata;
};

export default fetchNftMetadata;
