import { isMainNetConnected, isTestNetConnected } from './network';

export const isTestEnv = () =>
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_NET === 'testnet';

export const isWalletAndNetworkMatch = async (
  currentChainId?: string | number
): Promise<boolean> =>
  (isTestEnv() && (await isTestNetConnected(currentChainId))) ||
  (!isTestEnv() && (await isMainNetConnected(currentChainId)));
