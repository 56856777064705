import decodeTransaction from './decodeTransaction';
import TransactionRow from '../models/TransactionRow';
import { getStringDate, getStringTime } from './getStringDate';
import { isTestEnv } from './checkIfTest';

export type Response = {
  result: any[];
  status: string;
  message: string;
};

const fetchWalletTransactions = async (walletAddress: string) => {
  const url = `https://api${
    isTestEnv() ? '-testnet' : ''
  }.snowtrace.io/api?module=account&action=txlist&address=${walletAddress}&startblock=1&endblock=99999999&sort=desc&apikey=${
    process.env.REACT_APP_SNOWTRACE_API_KEY || ''
  }`;
  const response = await fetch(url);
  const jsonResponse = await response.json();
  const transactions = jsonResponse.result.map(
    (transaction: TransactionRow) => {
      const decoded = decodeTransaction(transaction.input);
      if (decoded) {
        return {
          ...transaction,
          functionName: decoded.name,
          date:
            getStringDate(parseInt(transaction.timeStamp)) +
            ' ' +
            getStringTime(parseInt(transaction.timeStamp)),
          valueInAVAX:
            decoded.name !== 'withdrawBalance'
              ? window.web3Client.utils.fromWei(transaction.value, 'ether') +
                ' AVAX'
              : '-',
        };
      } else {
        return null;
      }
    }
  );
  return transactions.filter((transaction: any) => !(transaction === null));
};

export default fetchWalletTransactions;
