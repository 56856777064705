import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  Paper,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExternalLink, InfoCircle } from 'tabler-icons-react';
import { ReactComponent as AvaxIcon } from '../../assets/avax.svg';
import sanitizeListing, { SanitizedListing } from '../../utils/sanitizeListing';
import SharedDataContext from '../../components/SharedDataContext';
import getListingById from '../../utils/getListingById';
import {
  claimCollateral,
  fundListing,
  cancelListing,
  repayForListing,
} from '../../utils/modifyListing';
import styles from './styles.module.css';

type Props = {
  walletAddress: string;
  onConnectWallet?: () => void;
};

const ListingDetails = ({ walletAddress, onConnectWallet }: Props) => {
  const navigate = useNavigate();
  const { listingId = 0 } = useParams();
  const [listing, setListing] = useState<SanitizedListing>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [buttonLoad, setButtonLoad] = useState(false);

  const { wlCollections } = useContext(SharedDataContext);
  const isVerifiedCollection = wlCollections.includes(
    listing?.nft.address.toLowerCase() ?? ''
  );

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const fetched = await getListingById(+listingId);
        const sanitized = await sanitizeListing(fetched);
        setListing(sanitized);
        setLoading(false);
      } catch (e: any) {
        setError(e.message);
        setLoading(false);
      }
    };
    fetchListings();
  }, [listingId]);

  const isFunded = listing?.status === 'FUNDED';
  const isListed = listing?.status === 'LISTED';
  const isExpired = listing?.timeLeft === 'Expired';
  const isClaimed = listing?.status === 'FUNDER_CLAIMED_COLLATERAL';

  const isOwner = listing?.owner === walletAddress;
  const isFunder = listing?.funder === walletAddress;

  const buttonText = isOwner ? 'Cancel Listing' : 'Fund Listing';
  const listingAttributesLength =
    listing?.nft?.metadata?.attributes?.length ?? 0;

  const onCancelListing = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await cancelListing(listing.id.toString(), walletAddress);
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  const onFundListing = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await fundListing(
        listing.id.toString(),
        walletAddress,
        listing.priceInWei,
        listing.repayPriceInWei
      );
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  const onRepayListing = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await repayForListing(
        listing.id.toString(),
        walletAddress,
        listing.repayPrice
      );
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  const onClaimCollateral = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await claimCollateral(
        listing?.id.toString(),
        walletAddress
      );
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  if (error) {
    return (
      <Text align="center" py="xl">
        {error}
      </Text>
    );
  }

  return (
    <Container size="xl" py="xl">
      <Grid gutter="xl">
        <Grid.Col xs={12} sm={4}>
          <Paper
            shadow="xs"
            radius="md"
            p="lg"
            sx={(theme) => ({
              height: '100%',
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
            })}
          >
            <Stack
              style={{ height: '100%', position: 'relative' }}
              justify="space-evenly"
              align="center"
              spacing="xs"
            >
              {!loading ? (
                <>
                  <Image
                    src={listing?.nft.metadata?.image}
                    alt={listing?.nft.metadata?.name}
                    style={{ width: '80%' }}
                    imageProps={{
                      style: {
                        aspectRatio: '1 / 1',
                        objectFit: 'contain',
                        background: '#373a40',
                        borderRadius: 8,
                      },
                    }}
                  />
                  <Box sx={{ textAlign: 'center' }}>
                    <Badge
                      color={isVerifiedCollection ? 'teal' : 'pink'}
                      variant="light"
                      mb="xs"
                    >
                      {isVerifiedCollection ? 'Verified' : 'Not Verified'}
                    </Badge>
                    <Text align="center" weight="bold">
                      {' '}
                      {listing?.nft.metadata?.name}{' '}
                    </Text>
                    <Text align="center">
                      {listing?.nft.metadata?.description}
                    </Text>
                  </Box>
                </>
              ) : (
                <>
                  <Skeleton radius="md" height={250} />
                  <Skeleton radius="xl" height={8} width="60%" />
                  <Skeleton radius="xl" height={8} width="40%" />
                </>
              )}
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col xs={12} sm={8}>
          <Paper
            shadow="xs"
            radius="md"
            p="lg"
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
            })}
          >
            {!loading ? (
              <Text size="xl">
                Loan for{' '}
                <Text component="span" size="xl" weight="bold">
                  {listing?.nft.metadata?.name}
                </Text>
              </Text>
            ) : (
              <Skeleton radius="md" height={8} width="60%" mt="sm" mb="lg" />
            )}
            <Divider
              my="sm"
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
              })}
            />
            {!loading && (
              <Group spacing="lg" direction="column">
                {isFunded && walletAddress && (
                  <Group>
                    <Group direction="row" spacing="xs">
                      <Text> Funded On:&nbsp;</Text>
                      <Text> {listing?.dateFunded} </Text>
                    </Group>
                    <Group spacing="xs">
                      <Text> Time left:&nbsp;</Text>
                      <Text> {listing?.timeLeft} </Text>
                    </Group>
                  </Group>
                )}

                {isListed && walletAddress && (
                  <Group direction="row" spacing={0}>
                    <Text> Listed On:&nbsp;</Text>
                    <Text> {listing?.dateListed} </Text>
                  </Group>
                )}

                <Group spacing="xl">
                  <Group direction="column" spacing={0}>
                    <Text> Period: </Text>
                    <Text> {listing?.repayDays} days </Text>
                  </Group>
                  <Group direction="column" spacing={0}>
                    <Text> Fund Amount: </Text>
                    <Group spacing="xs">
                      <AvaxIcon className={styles.avaxIcon} />
                      <Text> {listing?.price} </Text>
                    </Group>
                  </Group>
                  {/* <Group direction="column" spacing={0}>
                    <Text> Floor </Text>
                    <Text> {listing?.floor} </Text>
                  </Group>
                  <Group direction="column" spacing={0}>
                    <Text> LTF </Text>
                    <Text> {listing?.ltf}% </Text>
                  </Group> */}
                  <Group direction="column" spacing={0}>
                    <Text> APY </Text>
                    <Text> {listing?.apy}% </Text>
                  </Group>
                </Group>
                <Group direction="row" spacing="lg">
                  <Group direction="column" spacing={0}>
                    <Text> Payback Amount </Text>
                    <Group spacing="xs">
                      <AvaxIcon className={styles.avaxIcon} />
                      <Text> {listing?.repayPrice} </Text>
                    </Group>
                  </Group>
                  <Group direction="column" spacing={0}>
                    <Text> Return Rate </Text>
                    <Text> {listing?.returnRate}% </Text>
                  </Group>
                </Group>
                <Group spacing="xs">
                  <InfoCircle />
                  <Text>
                    {' '}
                    There is a 10% lender fee from the total interest.{' '}
                  </Text>
                </Group>
                <Group>
                  {isClaimed ? (
                    walletAddress && <Text> Funder claimed collateral </Text>
                  ) : (
                    <Text> This loan is {listing?.status.toLowerCase()} </Text>
                  )}
                </Group>
                {!walletAddress && (
                  <Group direction="column" spacing="xs">
                    <Text>
                      {' '}
                      Connect your wallet to fund, pay back, claim or cancel
                      this loan{' '}
                    </Text>
                    <Button radius="xl" onClick={onConnectWallet}>
                      {' '}
                      Connect Wallet{' '}
                    </Button>
                  </Group>
                )}

                {isListed && walletAddress && (
                  <Button
                    radius="xl"
                    color={isOwner ? 'red' : 'teal'}
                    loading={buttonLoad}
                    onClick={() => {
                      if (isOwner) {
                        onCancelListing(listing);
                      } else {
                        onFundListing(listing);
                      }
                    }}
                  >
                    {buttonText}
                  </Button>
                )}

                {isFunded && isOwner && !isExpired && walletAddress && (
                  <Button
                    color="lime"
                    loading={buttonLoad}
                    onClick={() => {
                      onRepayListing(listing);
                    }}
                  >
                    Repay
                  </Button>
                )}

                {isExpired && isFunder && isFunded && walletAddress && (
                  <Button
                    color="orange"
                    loading={buttonLoad}
                    onClick={() => {
                      onClaimCollateral(listing);
                    }}
                  >
                    Claim Collateral
                  </Button>
                )}
              </Group>
            )}
            {loading && (
              <Group spacing="lg" my="xl" direction="column">
                <Skeleton radius="md" height={8} width="25%" />
                <Skeleton radius="md" height={8} width="50%" />
                <Skeleton radius="md" height={8} width="40%" />
                <Skeleton radius="md" height={8} width="50%" />
                <Skeleton radius="md" height={8} width="30%" />
                <Skeleton radius="md" height={8} width="65%" />
                <Skeleton radius="md" height={8} width="20%" />
                <Skeleton radius="md" height={8} width="20%" mt="lg" />
              </Group>
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          {listingAttributesLength > 0 && (
            <Paper
              shadow="xs"
              radius="md"
              p="lg"
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
              })}
            >
              <Group spacing="xs" position="center">
                {listing?.nft.metadata?.attributes.map((attribute, i) => (
                  <Paper px="md" py="xs" key={i}>
                    <Group direction="column" spacing={0}>
                      <Text weight="bold">{attribute.trait_type}:</Text>
                      <Text> {attribute.value} </Text>
                    </Group>
                  </Paper>
                ))}
              </Group>
            </Paper>
          )}
          {!loading && (
            <Group py="xl" position="center">
              <Button
                size="sm"
                variant="outline"
                rightIcon={<ExternalLink />}
                onClick={() => window.open(listing?.link, '_blank')}
              >
                View on Explorer
              </Button>
            </Group>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default ListingDetails;
