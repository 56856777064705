import Listing from '../Listing';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext } from 'react';
import { Box, Button, SimpleGrid, Text } from '@mantine/core';
import {
  getMyFundedLoans,
  getMyListedLoans,
} from '../../utils/getListingsByOwner';
import getListingsByFunder from '../../utils/getListingsByFunder';
import {
  cancelListing,
  claimCollateral,
  repayForListing,
} from '../../utils/modifyListing';
import { SanitizedListing } from '../../utils/sanitizeListing';
import ListingSkeleton from '../Listing/ListingSkeleton';
import SharedDataContext from '../SharedDataContext';

type Props = {
  walletAddress: string;
  status: string;
};

type Data = {
  data: {
    walletAddress: string;
    listing: SanitizedListing;
  };
};

const ListNftsByStatus = ({ walletAddress, status }: Props) => {
  const navigate = useNavigate();
  const [listings, setListings] = useState<SanitizedListing[]>([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoad, setButtonLoad] = useState(false);

  const { wlCollections } = useContext(SharedDataContext);

  useEffect(() => {
    const getFundedListings = async () => {
      const fetched = await getListingsByFunder(walletAddress);
      setListings(fetched);
      setTimeout(() => setLoading(false), 250);
    };

    const getMyListedListings = async () => {
      const fetched = await getMyListedLoans(walletAddress);
      setListings(fetched);
      setLoading(false);
    };

    const getMyFundedListings = async () => {
      const fetched = await getMyFundedLoans(walletAddress);
      setListings(fetched);
      setTimeout(() => setLoading(false), 250);
    };

    switch (status) {
      case 'OWNED':
        getMyListedListings();
        break;
      case 'MY_FUNDED':
        getMyFundedListings();
        break;
      case 'FUNDED':
        getFundedListings();
        break;
      default:
        break;
    }
  }, [walletAddress, status]);

  const onCancelListing = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await cancelListing(listing.id.toString(), walletAddress);
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  const onRepayListing = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await repayForListing(
        listing.id.toString(),
        walletAddress,
        listing.repayPrice
      );
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  const onClaimCollateral = useCallback(
    async (listing: SanitizedListing) => {
      setButtonLoad(true);
      const result = await claimCollateral(
        listing?.id.toString(),
        walletAddress
      );
      setButtonLoad(false);
      if (result) {
        setTimeout(() => navigate('/'), 250);
      }
    },
    [walletAddress, navigate]
  );

  const onClick = ({ data }: Data) => {
    if (status === 'OWNED') {
      onCancelListing(data.listing);
    } else if (status === 'MY_FUNDED') {
      onRepayListing(data.listing);
    } else {
      onClaimCollateral(data.listing);
    }
  };

  if (!loading && listings.length === 0) {
    return (
      <>
        {status === 'OWNED' && (
          <Box p="md">
            <Text weight="bold"> You do not have any listings </Text>
            <Link to="/new-listing">
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              >
                Create a Loan Listing
              </Button>
            </Link>
          </Box>
        )}
        {status === 'MY_FUNDED' && (
          <Box p="md">
            <Text weight="bold"> You don't have any funded listings </Text>
            <Link to="/new-listing">
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              >
                Create a Loan Listing
              </Button>
            </Link>
          </Box>
        )}
        {status === 'FUNDED' && (
          <Box p="md">
            <Text weight="bold"> You have not funded any listings </Text>
            <Link to="/listings">
              <Button
                variant="gradient"
                gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              >
                Fund a Listing
              </Button>
            </Link>
          </Box>
        )}
      </>
    );
  }

  return (
    <SimpleGrid
      cols={6}
      spacing="sm"
      breakpoints={[
        { maxWidth: 1250, cols: 5 },
        { maxWidth: 1024, cols: 4 },
        { maxWidth: 868, cols: 3 },
        { maxWidth: 650, cols: 2 },
        { maxWidth: 480, cols: 1 },
      ]}
    >
      {!loading
        ? listings.map((listing) => {
            let isDisabled = false;
            const redButton = listing.status === 'LISTED' ? true : false;
            const actionText =
              listing.status === 'LISTED'
                ? 'Cancel Listing'
                : status === 'MY_FUNDED'
                ? 'Repay Loan'
                : 'Claim Collateral';
            if (
              (status === 'OWNED' && listing.timeLeft === 'Expired') ||
              (status === 'MY_FUNDED' && listing.timeLeft === 'Expired') ||
              (status === 'FUNDED' && !(listing.timeLeft === 'Expired'))
            ) {
              isDisabled = true;
            }
            return (
              <Listing
                id={listing.id}
                key={listing.id}
                data={{
                  walletAddress: walletAddress,
                  listingId: listing.id,
                }}
                listingInfo={{
                  price:
                    listing.status === 'LISTED'
                      ? listing.price
                      : listing.repayPrice,
                  dateDue: listing.dateDue.endsWith('/1970')
                    ? undefined
                    : listing.dateDue,
                }}
                tag={
                  wlCollections.includes(listing.nft.address.toLowerCase())
                    ? 'Verified'
                    : 'Not Verified'
                }
                tagColor={
                  wlCollections.includes(listing.nft.address.toLowerCase())
                    ? 'teal'
                    : 'pink'
                }
                isClickToVisitEnabled
                name={listing.nft.metadata?.name ?? ''}
                image={listing.nft.metadata?.image ?? ''}
                actionText={actionText}
                buttonColor={redButton ? 'red' : 'blue'}
                isDisabled={isDisabled}
                loading={buttonLoad}
                actionFn={() =>
                  onClick({
                    data: {
                      walletAddress,
                      listing: listing,
                    },
                  })
                }
              />
            );
          })
        : Array(12)
            .fill('')
            .map((el, i) => <ListingSkeleton key={i} />)}
    </SimpleGrid>
  );
};

export default ListNftsByStatus;
