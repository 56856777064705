import LenderContract from '../contracts/NFTLender.json';
import { MAIN_NETWORK_ID, TEST_NETWORK_ID } from './network';
import { isTestEnv, isWalletAndNetworkMatch } from './checkIfTest';

const contractABI = JSON.stringify(LenderContract.abi);
const networkID = isTestEnv() ? TEST_NETWORK_ID : MAIN_NETWORK_ID;
const contractAddress = LenderContract.networks[networkID].address;

type ContractInstanceResponse = {
  instance: any;
  address: string;
  errorWithNetwork: boolean;
};

const fetchLenderContractInstance =
  async (): Promise<ContractInstanceResponse> => {
    const walletNetworkMatch = await isWalletAndNetworkMatch();

    if (!walletNetworkMatch) {
      return { instance: null, address: '', errorWithNetwork: true };
    }

    const { Contract } = window.web3Client.eth;
    const instance = new Contract(JSON.parse(contractABI), contractAddress);
    return { instance, address: contractAddress, errorWithNetwork: false };
  };

export default fetchLenderContractInstance;
