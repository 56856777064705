import Web3 from 'web3';
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { NotificationsProvider } from '@mantine/notifications';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { Web3ReactProvider } from '@web3-react/core';
import useFirebase from './hooks/useFirebase';
import SharedDataContext from './components/SharedDataContext';
import blockedCollections from './config/blockedCollections';
import wlCollections from './config/wlCollections';

type Props = {
  children: React.ReactNode;
};

const Wrapper = ({ children }: Props) => {
  useFirebase();

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'nft-lender-color-scheme',
    getInitialValueInEffect: true,
    defaultValue: 'dark',
  });

  const toggleColorScheme = useCallback(
    (value?: ColorScheme) => {
      setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    },
    [colorScheme, setColorScheme]
  );

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  useEffect(() => {
    if (!localStorage.getItem('nft-lender-color-scheme')) {
      const scheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
      setColorScheme(scheme);
    }
  }, [setColorScheme]);

  const getLibrary = (provider: any) => {
    const web3 = new Web3(provider);
    window.web3Client = web3;
  };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <NotificationsProvider autoClose={false}>
          <SharedDataContext.Provider
            value={{
              wlCollections,
              blockedCollections,
              toggleColorScheme,
              colorScheme,
            }}
          >
            <Web3ReactProvider getLibrary={getLibrary}>
              {children}
            </Web3ReactProvider>
          </SharedDataContext.Provider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default Wrapper;
