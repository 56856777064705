import { showNotification, updateNotification } from '@mantine/notifications';
import { Check, X } from 'tabler-icons-react';

export const showCustomNotification = (
  condition: string,
  id: string,
  message?: string
) => {
  showNotification({
    title: condition,
    message:
      message ||
      (condition === 'LOADING'
        ? 'Transaction ongoing, please make sure to confirm or reject the transaction.'
        : 'Failed to get listings, please make sure you are connected to the right network.'),
    id: id,
    loading: condition === 'LOADING',
    disallowClose: condition === 'LOADING',
    color: condition === 'FAILED' ? 'red' : 'blue',
    icon: condition === 'FAILED' ? <X /> : null,
  });
};

export const updateCustomNotification = (
  condition: 'SUCCESS' | 'FAILED',
  message: string,
  id: string
) => {
  updateNotification({
    id: id,
    color: condition === 'SUCCESS' ? 'green' : 'red',
    message: message,
    title: condition,
    icon: condition === 'SUCCESS' ? <Check /> : <X />,
    loading: false,
    autoClose: 4000,
  });
};
