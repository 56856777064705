import {
  Button,
  Container,
  Grid,
  Group,
  Paper,
  Tabs,
  Text,
  Table,
  Code,
} from '@mantine/core';
import Blockies from 'react-blockies';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Photo,
  FileCode,
  Perspective,
  X,
  MoonStars,
  Sun,
} from 'tabler-icons-react';
import { useWeb3React } from '@web3-react/core';
import { getBalance, withdrawBalance } from '../../utils/contractBalance';
import ListNfts from '../../components/ListNfts';
import ListNftsByStatus from '../../components/ListNftsByStatus';
import fetchWalletTransactions from '../../utils/fetchWalletTransactions';
import SharedDataContext from '../../components/SharedDataContext';
import type TransactionRow from '../../models/TransactionRow';
import styles from './styles.module.css';

type Props = {
  walletAddress: string;
};

const Profile = ({ walletAddress }: Props) => {
  const [isCopyingWalletAddress, setIsCopyingWalletAddress] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [, setWalletBalanceWei] = useState(0);

  const [internalBalance, setInternalBalance] = useState(0);
  const [internalBalanceWei, setInternalBalanceWei] = useState(0);

  const { deactivate } = useWeb3React();
  const [transactions, setTransactions] = useState<TransactionRow[]>([]);
  const { colorScheme, toggleColorScheme } = useContext(SharedDataContext);

  const copyWalletAddress = useCallback(() => {
    setIsCopyingWalletAddress(true);
    navigator.clipboard.writeText(walletAddress);
    setTimeout(() => {
      setIsCopyingWalletAddress(false);
    }, 1000);
  }, [walletAddress]);

  const updateWalletBalance = async (currentWalletAddress: string) => {
    const { web3Client: web3 } = window;
    const balance = await web3.eth.getBalance(currentWalletAddress);
    setWalletBalanceWei(+balance);

    const avax = web3.utils.fromWei(balance, 'ether');
    setWalletBalance(Math.round(avax * 1e4) / 1e4);
  };

  const updateInternalBalance = async (currentWalletAddress: string) => {
    const { web3Client: web3 } = window;
    const balance = await getBalance(currentWalletAddress);
    setInternalBalanceWei(+balance);

    const avax = web3.utils.fromWei(balance, 'ether');
    setInternalBalance(Math.round(avax * 1e4) / 1e4);
  };

  useEffect(() => {
    fetchTransactions(walletAddress);
    updateWalletBalance(walletAddress);
    updateInternalBalance(walletAddress);
  }, [walletAddress]);

  const fetchTransactions = async (walletAddress: string) => {
    const transactions = await fetchWalletTransactions(walletAddress);
    setTransactions(transactions);
  };

  const onWithdrawBalance = useCallback(async () => {
    const { web3Client: web3 } = window;
    setIsWithdrawing(true);
    try {
      const result = await withdrawBalance(
        walletAddress,
        web3.utils.toBN(internalBalanceWei),
        walletAddress
      );
      if (result) {
        updateInternalBalance(walletAddress);
        updateWalletBalance(walletAddress);
      }
    } catch (error) {}
    setIsWithdrawing(false);
  }, [walletAddress, internalBalanceWei]);

  const rows = transactions.map((transaction: TransactionRow) => {
    return (
      <tr key={transaction.hash}>
        <td>{transaction.functionName}</td>
        <td>{transaction.valueInAVAX}</td>
        <td>{transaction.date}</td>
      </tr>
    );
  });

  return (
    <Container py="lg" size="xl">
      <Paper>
        <Grid py="xs">
          <Grid.Col span={1} sm={2}>
            <Blockies
              seed={walletAddress.toLowerCase()}
              className={styles.walletAvatar}
              scale={24}
              size={8}
            />
          </Grid.Col>
          <Grid.Col span={11} sm={10}>
            <Group
              position="apart"
              direction="column"
              sx={{ height: '100%', justifyContent: 'space-around' }}
            >
              <Group direction="column" spacing={0}>
                <Text size="lg" weight="bold">
                  Profile
                </Text>
                <Text
                  mb={4}
                  onClick={() => toggleColorScheme()}
                  sx={{
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  Toggle Theme:{' '}
                  {colorScheme === 'light' ? (
                    <MoonStars size={20} />
                  ) : (
                    <Sun size={20} />
                  )}
                  <Code>Ctrl + J</Code>
                </Text>
                <Text sx={{ cursor: 'pointer' }} onClick={copyWalletAddress}>
                  Wallet Address:{' '}
                  {walletAddress.slice(0, 5) + '...' + walletAddress.slice(-4)}{' '}
                  <i>({isCopyingWalletAddress ? 'copied' : 'copy'})</i>
                </Text>
                <Text>Wallet Balance: {walletBalance} AVAX</Text>
                <Button
                  mt="xs"
                  size="xs"
                  color="red"
                  variant="outline"
                  leftIcon={<X size={12} />}
                  onClick={() => {
                    deactivate();
                    localStorage.removeItem('lastConnected');
                  }}
                >
                  Disconnect
                </Button>
              </Group>
              <Group align="center">
                <Text>Internal Balance: {internalBalance} AVAX</Text>
                {internalBalanceWei > 0 && (
                  <Button onClick={onWithdrawBalance} loading={isWithdrawing}>
                    Withdraw to Wallet
                  </Button>
                )}
              </Group>
            </Group>
          </Grid.Col>
        </Grid>
      </Paper>
      <Tabs variant="outline" mt="lg">
        <Tabs.Tab label="My NFTs" icon={<Photo size={14} />}>
          <ListNfts walletAddress={walletAddress} />
        </Tabs.Tab>
        <Tabs.Tab label="My Created Loans" icon={<Perspective size={14} />}>
          <ListNftsByStatus walletAddress={walletAddress} status="OWNED" />
        </Tabs.Tab>
        <Tabs.Tab label="Outstanding Loans" icon={<Perspective size={14} />}>
          <ListNftsByStatus walletAddress={walletAddress} status="MY_FUNDED" />
        </Tabs.Tab>
        <Tabs.Tab label="Loans I've Funded" icon={<Perspective size={14} />}>
          <ListNftsByStatus walletAddress={walletAddress} status="FUNDED" />
        </Tabs.Tab>
        <Tabs.Tab label="My Transactions" icon={<FileCode size={14} />}>
          <Table>
            <thead>
              <tr>
                <th>Function Name</th>
                <th>Paid Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Tabs.Tab>
      </Tabs>
    </Container>
  );
};

export default Profile;
