import { useCallback } from 'react';
import { ExternalLink } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Box,
  useMantineTheme,
} from '@mantine/core';
import ListingInfo, { ListingInfoProps } from './ListingInfo';
import { ReactComponent as AvaxIcon } from '../../assets/avax.svg';
import styles from './styles.module.css';

type Props = {
  id: string | number;
  data?: any;
  tag?: string;
  tagColor?: string;
  name: string;
  image: string;
  repayPrice?: number;
  actionText?: string;
  buttonColor?: string;
  isDisabled?: boolean;
  isClickToVisitEnabled?: boolean;
  listingInfo?: ListingInfoProps;
  actionFn?: (data: any) => void;
  loading?: boolean;
};

const Listing = ({
  id,
  data,
  name,
  image,
  tag,
  tagColor,
  listingInfo,
  actionText,
  actionFn,
  isDisabled,
  isClickToVisitEnabled,
  buttonColor = 'blue',
  loading,
}: Props) => {
  const theme = useMantineTheme();

  const onButtonClick = useCallback(() => {
    if (actionFn && data) {
      actionFn(data);
    }
  }, [actionFn, data]);

  const renderImage = useCallback(
    () => (
      <Image
        src={image}
        alt={name}
        radius={8}
        withPlaceholder
        imageProps={{
          style: {
            aspectRatio: '1 / 1',
            objectFit: 'contain',
            background:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[2],
          },
        }}
      />
    ),
    [image, name, theme]
  );

  return (
    <Card shadow="sm" p="lg" radius="md" className={styles.card}>
      <Group position="apart" direction="column" className={styles.cardContent}>
        <Box className={styles.bodyWrapper}>
          {isClickToVisitEnabled ? (
            <Link to={`/listing/${id}`}>{renderImage()}</Link>
          ) : (
            renderImage()
          )}

          {isClickToVisitEnabled && (
            <Box className={styles.externalLink}>
              <ExternalLink />
            </Box>
          )}

          <Box className={styles.floatingTag}>
            {!!tag && (
              <Badge color={tagColor || 'pink'} variant="filled">
                {tag}
              </Badge>
            )}
          </Box>

          <Text weight={500} mt="xs">
            {name}
          </Text>

          {listingInfo && <ListingInfo data={listingInfo} />}
        </Box>
        {listingInfo?.price && (
          <Group spacing={2} direction="column">
            <Group>
              <Text>
                Loan{' '}
                {listingInfo.interestAmount && (
                  <Text component="span" color="teal" weight="bold">
                    + Return
                  </Text>
                )}
              </Text>
            </Group>
            <Group spacing={8}>
              <AvaxIcon className={styles.avaxIcon} />
              <Text weight="bold">
                {listingInfo.price}
                {listingInfo.interestAmount && (
                  <Text color="teal" component="span">
                    {' '}
                    + {listingInfo.interestAmount}
                  </Text>
                )}
              </Text>
            </Group>
          </Group>
        )}
        {actionText && (
          <Group className={styles.buttonWrapper}>
            <Button
              fullWidth
              variant="light"
              loading={loading}
              color={buttonColor}
              disabled={isDisabled}
              onClick={onButtonClick}
            >
              {actionText}
            </Button>
          </Group>
        )}
      </Group>
    </Card>
  );
};

export default Listing;
