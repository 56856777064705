import { showCustomNotification } from '../components/NotificationComponent';
import fetchLenderContract from './fetchLenderContract';
import sanitizeListing, { SanitizedListing } from './sanitizeListing';

const getListingsByFunder = async (
  walletAddress: string
): Promise<SanitizedListing[]> => {
  const id = Date.now().toString();
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    showCustomNotification('FAILED', id);
    return [];
  }

  const listings = await lenderInstance.methods
    .getListingsByAddress(walletAddress, 2, true, 0, 0)
    .call();

  const sanitized: SanitizedListing[] = await Promise.all(
    listings.map(sanitizeListing)
  );
  return sanitized;
};

export default getListingsByFunder;
