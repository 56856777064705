import { useNavigate } from 'react-router';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SimpleGrid, Text, Box, Button } from '@mantine/core';
import VisibilitySensor from 'react-visibility-sensor';
import type NFT from '../../models/NFT';
import useWalletNfts from '../../hooks/useWalletNfts';
import ListingSkeleton from '../Listing/ListingSkeleton';
import ListingModal from '../ListingModal';
import Listing from '../Listing';
import SharedDataContext from '../SharedDataContext';

type Props = {
  walletAddress: string;
};

const ListNfts = ({ walletAddress }: Props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNft, setSelectedNft] = useState<NFT | null>(null);
  const [lastWalletAddress, setLastWalletAddress] = useState(walletAddress);

  const { wlCollections } = useContext(SharedDataContext);

  const { nfts, allNfts, refreshNfts, fetchMoreNfts, loading } =
    useWalletNfts(walletAddress);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSelectedNft(null);
    }, 500);
  }, []);

  const onCreateListingStart = useCallback((nft: NFT) => {
    setSelectedNft(nft);
    setIsModalOpen(true);
  }, []);

  const onCreateListingEnd = useCallback(() => {
    onCloseModal();
    setTimeout(() => navigate('/'), 250);
  }, [onCloseModal, navigate]);

  useEffect(() => {
    if (walletAddress !== lastWalletAddress) {
      setLastWalletAddress(walletAddress);
      refreshNfts(walletAddress);
    }
  }, [walletAddress, lastWalletAddress, refreshNfts]);

  const renderLoadingSkeleton = (count = 6) => (
    <>
      {Array(nfts.length === 0 ? 12 : count)
        .fill('')
        .map((el, i) => (
          <ListingSkeleton key={i} />
        ))}
    </>
  );

  if (!loading && !nfts.length) {
    return (
      <Box p="md">
        <Text size="lg" weight="bold" mb="md">
          No NFTs found in your wallet
        </Text>
      </Box>
    );
  }

  return (
    <>
      <SimpleGrid
        cols={6}
        spacing="sm"
        breakpoints={[
          { maxWidth: 1250, cols: 5 },
          { maxWidth: 1024, cols: 4 },
          { maxWidth: 868, cols: 3 },
          { maxWidth: 650, cols: 2 },
          { maxWidth: 480, cols: 1 },
        ]}
      >
        {nfts.map((nft) => (
          <Listing
            id={nft.id}
            data={nft}
            key={nft.id + nft.address}
            name={nft.metadata?.name ?? ''}
            image={nft.metadata?.image ?? ''}
            actionText="Create Listing"
            actionFn={onCreateListingStart}
            tag={
              wlCollections.includes(nft.address.toLowerCase())
                ? 'Verified'
                : 'Not Verified'
            }
            tagColor={
              wlCollections.includes(nft.address.toLowerCase())
                ? 'teal'
                : 'pink'
            }
          />
        ))}
        {loading && !nfts.length && renderLoadingSkeleton()}
        {nfts.length > 0 && nfts.length < allNfts.length && (
          <>
            <VisibilitySensor
              onChange={(isVisible: boolean) =>
                isVisible ? fetchMoreNfts() : null
              }
            >
              {renderLoadingSkeleton(2)}
            </VisibilitySensor>
            <Box
              sx={{
                bottom: -30,
                zIndex: 10,
                left: '15vw',
                transform: 'translateX(-50%)',
                position: 'relative',
              }}
            >
              <Button onClick={fetchMoreNfts} disabled={loading}>
                Load More NFTs
              </Button>
            </Box>
          </>
        )}
      </SimpleGrid>
      <ListingModal
        isOpen={isModalOpen}
        selectedNft={selectedNft}
        onClose={onCloseModal}
        onDone={onCreateListingEnd}
        walletAddress={walletAddress}
      />
    </>
  );
};

export default ListNfts;
