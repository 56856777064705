import getNftWithMetadata from './getNftWithMetadata';
import { getStringDate } from './getStringDate';
import { isTestEnv } from './checkIfTest';
import type NFT from '../models/NFT';

export type SanitizedListing = {
  id: number;
  price: number;
  priceInWei: number;
  repayDays: number;
  repayPrice: number;
  repayPriceInWei: number;
  interestAmount: number;
  interestAmountInWei: number;
  returnRate: number;
  apy: number;
  dateFunded: string;
  dateListed: string;
  dateRepaid: string;
  dateDue: string;
  timeLeft: string;
  floor: number;
  ltf: number;
  status: string;
  owner: string;
  funder: string;
  link: string;
  nft: NFT;
};

const getReturnRate = (priceInWei: number, repayPriceInWei: number) =>
  Math.round(((repayPriceInWei * 100) / priceInWei - 100) * 10) / 10;

const getAPY = (
  priceInWei: number,
  repayPriceInWei: number,
  repayDays: number
) =>
  Math.round(
    (getReturnRate(priceInWei, repayPriceInWei) / repayDays) * 365 * 10
  ) / 10;

const getDurationInDays = (durationInSeconds: number) =>
  Math.round(durationInSeconds / (60 * 60 * 24));

const getRemainingDuration = (fundedDate: number, repayDays: number) => {
  const now = Date.now() / 1000;
  const remainingDuration = repayDays - (now - fundedDate);
  if (remainingDuration < 0 && fundedDate > 0) {
    return 'Expired';
  }
  const remainingSeconds = Math.round(remainingDuration % 60);
  const remainingMinutes = Math.floor(remainingDuration / 60) % 60;
  const remainingHours = Math.floor(remainingDuration / 60 / 60) % 24;
  const remainingDays = Math.floor(remainingDuration / 60 / 60 / 24);
  return `${remainingDays > 0 ? remainingDays + 'd ' : ''}${
    remainingHours > 0 ? remainingHours + 'h ' : ''
  }${remainingMinutes > 0 ? remainingMinutes + 'm ' : ''}${
    remainingSeconds > 0 ? remainingSeconds + 's' : ''
  }`;
};

const getStatus = (numStatus: number) => {
  switch (numStatus) {
    case 0:
      return 'LISTED';
    case 1:
      return 'CANCELED';
    case 2:
      return 'FUNDED';
    case 3:
      return 'REPAID';
    case 4:
      return 'FUNDER_CLAIMED_COLLATERAL';

    default:
      return '';
  }
};

const sanitizeListing = async (listing: any): Promise<SanitizedListing> => {
  const { web3Client } = window;
  const nftWithMetaData = await getNftWithMetadata(
    listing.nftContract,
    listing.tokenId
  );

  const link = `https://${isTestEnv() ? 'testnet.' : ''}snowtrace.io/token/${
    nftWithMetaData.address
  }?a=${nftWithMetaData.id}`;

  return {
    id: +listing.id,
    price: web3Client.utils.fromWei(`${listing.price}`, 'ether'),
    priceInWei: +listing.price,
    repayDays: getDurationInDays(+listing.repayDays),
    repayPrice: web3Client.utils.fromWei(`${listing.repayPrice}`, 'ether'),
    repayPriceInWei: +listing.repayPrice,
    interestAmount: web3Client.utils.fromWei(
      `${+listing.repayPrice - +listing.price}`,
      'ether'
    ),
    interestAmountInWei: +listing.repayPrice - +listing.price,
    returnRate: getReturnRate(+listing.price, +listing.repayPrice),
    dateFunded: getStringDate(+listing.datesInfo.fundedDate),
    dateListed: getStringDate(+listing.datesInfo.listedDate),
    dateRepaid: getStringDate(+listing.datesInfo.repaidDate),
    dateDue: getStringDate(+listing.datesInfo.fundedDate + +listing.repayDays),
    floor: 0,
    ltf: 0,
    owner: listing.owner,
    funder: listing.fundedBy,
    timeLeft: getRemainingDuration(
      +listing.datesInfo.fundedDate,
      +listing.repayDays
    ),
    apy: getAPY(
      +listing.price,
      +listing.repayPrice,
      getDurationInDays(+listing.repayDays)
    ),
    status: getStatus(+listing.status),
    nft: nftWithMetaData,
    link: link,
  };
};

export default sanitizeListing;
