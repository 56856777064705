import { Divider, Group, Text, useMantineTheme } from '@mantine/core';
import { Clock, Coin } from 'tabler-icons-react';

export type ListingInfoProps = {
  apy?: number;
  price?: number;
  duration?: number;
  returnRate?: number;
  interestAmount?: number;
  dateDue?: string;
};

type Props = {
  data: ListingInfoProps;
};

const ListingInfo = ({ data }: Props) => {
  const theme = useMantineTheme();
  const { apy, duration, returnRate, dateDue } = data;

  return (
    <>
      <Divider />
      <Group spacing={4} mt="sm" direction="column">
        <Group spacing="md">
          {duration && (
            <Group spacing={4}>
              <Clock color={theme.colors.teal[5]} />
              <Text size="sm" weight="bold">
                {duration} Days
              </Text>
            </Group>
          )}
          {returnRate && (
            <Group spacing={4}>
              <Coin color={theme.colors.teal[5]} />
              <Text size="sm" weight="bold">
                {returnRate}%
              </Text>
            </Group>
          )}
        </Group>
        <Group>
          {apy && (
            <Group spacing={4}>
              <Text size="md" weight="bold" color="teal">
                APY
              </Text>
              <Text size="sm" weight="bold">
                {apy}%
              </Text>
            </Group>
          )}
        </Group>
        {dateDue && (
          <Group spacing="xs">
            <Group spacing={4}>
              <Clock color={theme.colors.teal[5]} />
              <Text size="sm" weight="bold">
                Due Date
              </Text>
            </Group>
            <Text size="sm" weight="bold">
              {dateDue}
            </Text>
          </Group>
        )}
      </Group>
    </>
  );
};

export default ListingInfo;
