import fetchLenderContract from './fetchLenderContract';

const fetchListings = async (lastListingId = 0, maxListings = 0) => {
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    throw new Error(
      'Please make sure you are on the correct network before proceeding!'
    );
  }

  return lenderInstance.methods.getListings(lastListingId, maxListings).call();
};

export default fetchListings;
