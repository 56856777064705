export const getStringDate = (dateInSeconds: number) => {
  const dateObj = new Date(dateInSeconds * 1000);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getStringTime = (time: number) => {
  const dateObj = new Date(time * 1000);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
};
