import fetchLenderContract from './fetchLenderContract';

const getListingById = async (listingId: number) => {
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    throw new Error(
      'Please make sure you are on the correct network before proceeding!'
    );
  }

  return lenderInstance.methods.getListingById(listingId).call();
};

export default getListingById;
