import { Button, Group, Image, Modal } from '@mantine/core';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { connectors } from '../../utils/connectors';
import coinbase from '../../assets/Coinbase.svg';
import metamask from '../../assets/MetaMask.png';
import walletconnect from '../../assets/walletconnect.png';
import styles from './styles.module.css';

const getLastConnected = () => {
  const lastConnected = localStorage.getItem('lastConnected');
  switch (lastConnected) {
    case 'coinbaseWallet':
      return 'coinbaseWallet';
    case 'walletConnect':
      return 'walletConnect';
    case 'injected':
      return 'injected';
    default:
      return '';
  }
};

let errorShown = false;

const showActivateError = (error: any) => {
  if (!error.code && !errorShown) {
    errorShown = true;
    alert(
      'Error activating wallet. Make sure you have the correct network selected.'
    );
    setTimeout(() => {
      errorShown = false;
    }, 2000);
  }
};

type Props = {
  opened: boolean;
  onClose: () => void;
  setIsAutoConnecting: (value: boolean) => void;
};

const ConnectWalletModal = ({
  opened,
  onClose,
  setIsAutoConnecting,
}: Props) => {
  const { activate } = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const lastConnected = getLastConnected();
    if (activate && lastConnected) {
      setIsAutoConnecting(true);
      setTimeout(() => {
        activate(
          connectors[lastConnected],
          lastConnected !== 'injected' ? showActivateError : undefined
        );
        setTimeout(() => {
          setIsAutoConnecting(false);
        }, 250);
      }, 500);
    } else {
      setIsAutoConnecting(false);
    }
  }, [activate, setIsAutoConnecting]);

  return (
    <Modal
      size="sm"
      opened={opened}
      onClose={onClose}
      title="Choose a Network to Connect your Wallet"
      centered
      transition="fade"
      transitionDuration={600}
      transitionTimingFunction="ease"
    >
      <Group direction="column">
        <Button
          size="lg"
          fullWidth
          leftIcon={
            <Image className={styles.walletAvatar} src={metamask}></Image>
          }
          loading={loading}
          variant="outline"
          onClick={async () => {
            setLoading(true);
            await activate(connectors.injected);
            localStorage.setItem('lastConnected', 'injected');
            setLoading(false);
            onClose();
          }}
        >
          MetaMask Wallet
        </Button>
        <Button
          size="lg"
          fullWidth
          leftIcon={
            <Image className={styles.walletAvatar} src={walletconnect}></Image>
          }
          loading={loading}
          variant="outline"
          onClick={async () => {
            setLoading(true);
            await activate(connectors.walletConnect, showActivateError);
            localStorage.setItem('lastConnected', 'walletConnect');
            setLoading(false);
            onClose();
            window.location.reload();
          }}
        >
          Wallet Connect
        </Button>
        <Button
          size="lg"
          fullWidth
          leftIcon={
            <Image className={styles.walletAvatar} src={coinbase}></Image>
          }
          loading={loading}
          variant="outline"
          onClick={async () => {
            setLoading(true);
            await activate(connectors.coinbaseWallet, showActivateError);
            localStorage.setItem('lastConnected', 'coinbaseWallet');
            setLoading(false);
            onClose();
          }}
        >
          Coinbase Wallet
        </Button>
      </Group>
    </Modal>
  );
};

export default ConnectWalletModal;
