import NFT, { MetaData } from '../models/NFT';
import fetchNftMetadata from '../api/fetchNftMetadata';

const ipfsGateway = 'https://ipfs.io/ipfs/';

const getNftWithMetadata = async (
  nftAddress: string,
  nftId: string,
  nftUri?: string,
  nftApiMetadata?: string
): Promise<NFT> => {
  const initialMetaData = nftApiMetadata || '';

  let metadata: MetaData = {
    name: '',
    description: '',
    id: nftId,
    image: '',
    imageS: '',
    attributes: [],
  };

  try {
    metadata = nftApiMetadata
      ? JSON.parse(nftApiMetadata)
      : await fetchNftMetadata(nftAddress, nftId, ipfsGateway);
  } catch (error) {
    console.error(error);
  }

  if (metadata?.image?.startsWith('ipfs://')) {
    metadata.image = `${ipfsGateway}${metadata.image.split('ipfs://')[1]}`;
  } else if (metadata?.image?.includes('/ipfs/')) {
    metadata.image = `${ipfsGateway}${metadata.image.split('/ipfs/')[1]}`;
  }

  if (metadata.name) {
    metadata.name = `${metadata?.name || ''}`.slice(0, 50);
  } else {
    metadata.name = '';
  }

  if (!metadata.attributes) {
    metadata.attributes = [];
  }

  return {
    apiMetaData: initialMetaData,
    address: nftAddress,
    url: nftUri || '',
    id: nftId,
    metadata,
  };
};

export default getNftWithMetadata;
