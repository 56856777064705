import { Box, Group, Input, NumberInput, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  type: 'number' | 'text';
  value1?: any;
  value2?: any;
  min?: number;
  max?: number;
  placeholder1?: string;
  placeholder2?: string;
  onChange: (value1: any, value2: any) => void;
};

const FilterElement = ({
  type,
  min,
  max,
  value1,
  value2,
  placeholder1,
  placeholder2,
  onChange,
}: Props) => {
  const InputComponent = type === 'number' ? NumberInput : Input;

  const [localValue1, setLocalValue1] = useState(value1);
  const [localValue2, setLocalValue2] = useState(value2);

  const [debouncedValue1] = useDebouncedValue(localValue1, 300);
  const [debouncedValue2] = useDebouncedValue(localValue2, 300);

  useEffect(() => {
    setLocalValue1(value1);
    setLocalValue2(value2);
  }, [value1, value2]);

  const handleChange = useCallback(
    (event: any, name: string) => {
      const currentValue =
        type === 'number'
          ? !isNaN(event)
            ? Number(event)
            : undefined
          : event.target.value;

      if (name === 'value1') {
        setLocalValue1(currentValue);
      } else {
        setLocalValue2(currentValue);
      }
    },
    [type]
  );

  useEffect(() => {
    onChange(debouncedValue1, debouncedValue2);
  }, [onChange, debouncedValue1, debouncedValue2]);

  return (
    <Box>
      <Group spacing="xs" position="center">
        <InputComponent
          size="xs"
          min={type === 'number' ? min : undefined}
          max={type === 'number' ? max : undefined}
          precision={type === 'number' ? 2 : undefined}
          placeholder={
            type === 'number' && !placeholder1 ? 'MIN' : placeholder1
          }
          hideControls
          styles={{
            input: {
              textAlign: type === 'number' ? 'center' : 'left',
              paddingLeft: 16,
            },
          }}
          sx={{ width: type === 'number' ? 72 : '100%' }}
          onChange={(v: any) => handleChange(v, 'value1')}
          value={localValue1}
        />
        {type === 'number' && (
          <>
            <Text>TO</Text>
            <InputComponent
              size="xs"
              min={min}
              max={max}
              precision={type === 'number' ? 2 : 0}
              placeholder={
                type === 'number' && !placeholder2 ? 'MAX' : placeholder2
              }
              hideControls
              styles={{
                input: { width: 72, textAlign: 'center', paddingLeft: 4 },
              }}
              onChange={(v: any) => handleChange(v, 'value2')}
              value={localValue2}
            />
          </>
        )}
      </Group>
    </Box>
  );
};

export default FilterElement;
