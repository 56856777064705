import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { firebaseConfig } from '../config/firebase';

const useFirebase = () => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID) {
      const app = initializeApp(firebaseConfig);

      if (process.env.REACT_APP_ANALYTICS_ENABLED === 'yes') {
        setAnalytics(getAnalytics(app));
      }
    }
  }, []);

  return { analytics };
};

export default useFirebase;
