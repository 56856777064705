import {
  updateCustomNotification,
  showCustomNotification,
} from '../components/NotificationComponent';
import type NFT from '../models/NFT';
import fetchLenderContract from '../utils/fetchLenderContract';

type ListingData = {
  price: number;
  repayPrice: number;
  repayDays: number;
};

const addListing = async (
  walletAddress: string,
  listingData: ListingData,
  nft: NFT
) => {
  const id = Date.now().toString();
  showCustomNotification('LOADING', id);
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Please make sure you are on the correct network before proceeding!',
      id
    );
    return false;
  }

  const { price, repayPrice, repayDays } = listingData;

  const { web3Client } = window;
  const listingPrice = await lenderInstance.methods.getListingPrice().call();
  const priceInWei = web3Client.utils.toWei(price.toString(), 'ether');
  const repayPriceInWei = web3Client.utils.toWei(
    repayPrice.toString(),
    'ether'
  );

  const params = [priceInWei, nft.address, nft.id, repayPriceInWei, repayDays];
  try {
    await lenderInstance.methods.addListing(...params).send({
      from: walletAddress,
      value: +listingPrice,
    });
    updateCustomNotification(
      'SUCCESS',
      'Successfully created the listing, you can now find the listing in your profile under the My Created Listings.',
      id
    );
    return true;
  } catch (error) {
    updateCustomNotification(
      'FAILED',
      'Failed to add listing, try again and make sure to confirm the transaction.',
      id
    );
    return false;
  }
};

export default addListing;
