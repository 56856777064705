import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { isTestEnv } from './checkIfTest';

const CoinbaseWallet = new WalletLinkConnector({
  url: isTestEnv()
    ? 'https://api.avax-test.network/ext/bc/C/rpc'
    : 'https://api.avax.network/ext/bc/C/rpc',
  appName: 'P2P Lending',
});

const WalletConnect = new WalletConnectConnector({
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  supportedChainIds: isTestEnv() ? [43113] : [43114],
  rpc: {
    43113: 'https://api.avax-test.network/ext/bc/C/rpc',
    43114: 'https://api.avax.network/ext/bc/C/rpc',
  },
});

const Injected = new InjectedConnector({});

export const connectors = {
  injected: Injected,
  walletConnect: WalletConnect,
  coinbaseWallet: CoinbaseWallet,
};
