import {
  updateCustomNotification,
  showCustomNotification,
} from '../components/NotificationComponent';
import fetchLenderContract from './fetchLenderContract';

export const withdrawBalance = async (
  walletAddress: string,
  amount: number,
  destAddress: string
) => {
  const id = Date.now().toString();
  showCustomNotification('LOADING', id);
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Please make sure you are on the correct network before proceeding!',
      id
    );
    return false;
  }

  try {
    await lenderInstance.methods
      .withdrawBalance(amount, destAddress)
      .send({ from: walletAddress });

    updateCustomNotification(
      'SUCCESS',
      'Successfully withdrawn the balance, the funds have been sent to your wallet.',
      id
    );
    return true;
  } catch (error) {
    updateCustomNotification(
      'FAILED',
      'Failed to withdraw balance. Try again and make sure to confirm the transaction.',
      id
    );
    return false;
  }
};

export const getBalance = async (walletAddress: string) => {
  const { instance: lenderInstance, errorWithNetwork } =
    await fetchLenderContract();

  if (errorWithNetwork) {
    updateCustomNotification(
      'FAILED',
      'Failed to get balance, make sure you are on the right network',
      'notification'
    );
    return;
  }

  return lenderInstance.methods.getBalance(walletAddress).call();
};
