import { createContext } from 'react';

type SharedData = {
  wlCollections: string[];
  blockedCollections: string[];
  toggleColorScheme: () => void;
  colorScheme?: 'light' | 'dark';
};

const SharedDataContext = createContext<SharedData>({
  wlCollections: [],
  blockedCollections: [],
  toggleColorScheme: () => {},
});

export default SharedDataContext;
