import { Container } from '@mantine/core';
import ListNfts from '../../components/ListNfts';

type Props = {
  walletAddress: string;
};

const NewListing = ({ walletAddress }: Props) => (
  <Container p="md" size="xl">
    <ListNfts walletAddress={walletAddress} />
  </Container>
);

export default NewListing;
