// @ts-ignore
import abiDecoder from 'abi-decoder';
import LenderContract from '../contracts/NFTLender.json';

const lenderContract = LenderContract;
const contractABI = lenderContract.abi;
abiDecoder.addABI(contractABI);

const decodeTransaction = (data: string) => {
  const decodedData = abiDecoder.decodeMethod(data);
  return decodedData;
};

export default decodeTransaction;
