import NftResponse from '../models/NftResponse';
import { isTestEnv } from './checkIfTest';

const ipfsGateway = 'https://ipfs.io/ipfs/';

const generateUrl = (walletAddress: string) =>
  `https://deep-index.moralis.io/api/v2/${walletAddress}/nft?chain=avalanche${
    isTestEnv() ? '%20testnet' : ''
  }&format=decimal`;

const getTokenUri = (tokenUri: string) => {
  if (tokenUri.includes('/ipfs/')) {
    return `${ipfsGateway}${tokenUri.split('/ipfs/')[1]}`;
  } else if (tokenUri.startsWith('ipfs://')) {
    return `${ipfsGateway}${tokenUri.split('ipfs://')[1]}`;
  }
  return tokenUri;
};

const fetchWalletNfts = async (
  walletAddress: string
): Promise<NftResponse[]> => {
  const url = generateUrl(walletAddress);
  let response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY || '',
    },
  });

  const data = await response.json();

  try {
    return data.result.map((txn: NftResponse) => ({
      ...txn,
      token_uri: getTokenUri(txn.token_uri || ''),
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default fetchWalletNfts;
